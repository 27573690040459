<template>
	<div class="container" style="background: #fff;padding: 15px;">
		<el-tabs v-model="listState" @tab-click="filterFun">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
		</el-tabs>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="width: 75px;display: inline-block;text-align: right;">关键字: </label>
				<el-input v-model="searchKey" :placeholder="placeholderHold" style="width: 200px;" clearable></el-input>
			</div>
		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" v-if="listState==0">核销时间: </label>
				<label class="label" v-if="listState==1">退款时间: </label>
				<el-date-picker type="datetime" placeholder="选择日期" v-model="starTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss"></el-date-picker> ~
				<el-date-picker type="datetime" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="doorListFilter">查询</el-button>
				<el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container" v-if="listState==0">
			<el-table :data="groupData" v-loading="loading">
				<el-table-column prop="OrderNo" label="订单编号">
					<template slot-scope="scope">
						<P style="color:red" v-if='scope.row.SendTypeDisplay'>[{{scope.row.SendTypeDisplay}}]</P>
						<!-- @click='pushTorderdetail(scope.row)' -->
						<!-- style="color:#409EFF;cursor: pointer;" -->
						<p>{{scope.row.OrderNo}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="OrderMoney" label="订单金额"></el-table-column>
				<el-table-column prop="CompleteTime" label="核销时间"></el-table-column>
				<el-table-column prop="BrandName" label="店员">
					<template slot-scope="scope">
						<p>{{scope.row.EmployeeName}}</p>
						<p>{{scope.row.EmployeePhone}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="ProductName" label="商品" width="300">
					<template slot-scope="scope">
						<div style="width:300px;" class="twoSpless">
							<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="SpecValue" label="规格">
					<template slot-scope="scope">
						<p class="oneSpless">
							{{scope.row.SpecValue}}
							<span v-if="scope.row.SpecValue2">;{{scope.row.SpecValue2}}</span>
						</p>
						<p>{{scope.row.BarCode}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="ProductCount" label="数量"></el-table-column>
				<el-table-column prop="RealMoney" label="商品总计"></el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<div class="table-container" v-else>
			<el-table :data="refundData" v-loading="loading">
				<el-table-column prop="BrandName" label="退款单号">
					<template slot-scope="scope">
						<div>{{scope.row.SupportNo}}</div>
						<!-- <P style="color:red" v-if='scope.row.SendTypeDisplay'>[{{scope.row.SendTypeDisplay}}]</P>
						<p style="color:#409EFF;cursor: pointer;" @click='pushTorefundDetail(scope.row)'>{{scope.row.SupportNo}}</p> -->
					</template>
				</el-table-column>
				<el-table-column prop="RefundMoney" label="退款金额"></el-table-column>
				<el-table-column prop="CompleteTime" label="退款时间"></el-table-column>
				<el-table-column prop="OrderNo" label="原始订单"></el-table-column>
				<el-table-column prop="BrandName" label="店员">
					<template slot-scope="scope">
						<p>{{scope.row.EmployeeName}}</p>
						<p>{{scope.row.EmployeePhone}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="商品" width="300">
					<template slot-scope="scope">
						<div style="width:300px;" class="twoSpless">
							<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="规格">
					<template slot-scope="scope">
						<p class="oneSpless">
							{{scope.row.SpecValue}}
							<span v-if="scope.row.SpecValue2">;{{scope.row.SpecValue2}}</span>
						</p>
						<p>{{scope.row.BarCode}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="ProductCount" label="退款数量"></el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination v-if="Total1" style="margin:20px 0" @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
				 :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total1">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dataStatisOfflinePayorderList,
		dataStatisOfflinePayrefundList
	} from '@/api/TurnTomySelf'
	import config from '@/config/index'
	export default {
		data() {
			return {
				exportUrl: config.EXPORT_URL,
				placeholderHold: '订单编号',
				loading: false,
				listState: '0',
				listStateList: [{
						id: 0,
						value: '核销订单'
					},
					{
						id: 1,
						value: '退款单'
					}
				],
				searchKey: '',
				starTime: '',
				endTime: '',
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				groupData: [],
				//退款单
				currentPage1: 1,
				pageSize1: 20,
				Total1: 0,
				refundData: [],
				goUrls: config.GO_URL,
			}
		},
		created() {
			this.getNowDay()
			this.getList()
		},
		methods: {
			// 获取今日时间
			getNowDay() {
				this.starTime = this.$route.query.starTime;
				this.endTime = this.$route.query.endTime;
			},
			filterFun() {
				this.searchKey = ''
				this.getNowDay()
				//结算
				if (this.listState == 0) {
					this.currentPage = 1
					this.placeholderHold = "订单编号"
				}
				//退款
				else {
					this.currentPage1 = 1
					this.placeholderHold = "退款单号"
				}
				this.getList()
			},
			// 查询
			doorListFilter() {
				if (this.listState == 0) {
					this.currentPage = 1
				}
				//退款
				else {
					this.currentPage1 = 1
				}
				this.getList()
			},
			async getList() {
				try {
					this.loading = true
					if (this.listState == 0) {
						let data = {
							Keywords: this.searchKey,
							StartTime: this.starTime,
							EndTime: this.endTime,
							ShopId: this.$route.query.Id,
							Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
							Take: this.pageSize, // 取的数据
						}
						let result = await dataStatisOfflinePayorderList(data)
						this.Total = result.Result.Total;
						this.groupData = result.Result.Results || [];
					} else {
						let data = {
							Keywords: this.searchKey,
							StartTime: this.starTime,
							EndTime: this.endTime,
							ShopId: this.$route.query.Id,
							Skip: (this.currentPage1 - 1) * this.pageSize1, // 略过数据
							Take: this.pageSize1, // 取的数据
						}
						let result = await dataStatisOfflinePayrefundList(data)
						this.Total1 = result.Result.Total;
						this.refundData = result.Result.Results || [];
					}


				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			exportFun() {
				let url = this.exportUrl + (this.listState == 0 ? '/pc/dataStatisOfflinePay/orderExport?':'/pc/dataStatisOfflinePay/refundExport?') +
					'&Keywords=' + (this.searchKey ? encodeURIComponent(this.searchKey) : '') +
					'&StartTime=' + (this.starTime ? this.starTime : '') +
					'&EndTime=' + (this.endTime ? this.endTime : '') +
					'&ShopId=' + this.$route.query.Id
				window.open(url);
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},

			// 切换显示条数
			handleSizeChange1(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize1 = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange1(val) {
				console.log(`当前页: ${val}`);
				this.currentPage1 = val;
				this.getList();
			},
		}
	}
</script>

<style>
</style>
